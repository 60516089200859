import React from 'react'
import {connect} from "react-redux";
import {Link} from "react-router-dom"
import InsurancePartner from "./_components/InsurancePartner";
import StatusTypes from "../../constants/StatusTypes";
import InsurancePartnerAction from "../../stores/insurancePartner/InsurancePartnerAction";
import Loader from "react-loader-spinner";
import Pin from "../../assets/images/pin-orange.svg"
import InsurancePartnerDetail from "./_components/InsurancePartnerDetail";
import RouteEnum from "../../constants/RouteEnum";

const mapStateToProps = (state) => ({
    app: state.app,
    location: state.location,
    insurancePartner: state.insurancePartner
})

class Dashboard extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            insurancePartner: null
        }
    }


    init() {

        this.setState({
            loading: true
        })


        this.props.dispatch(InsurancePartnerAction.requestList()).then(() => {
            this.setState({
                loading: false
            })
        })
    }

    componentDidMount() {
        this.init()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.location !== prevProps.location) {
            this.init()
        }

    }

    openInsurancePartner = (id) => {
        this.setState({
            insurancePartner: id,
        })
    }

    closeInsurancePartner = () => {
        this.setState({
            insurancePartner: null,
        })
    }

    render() {

        if (!this.state.loading) {

            const {list} = this.props.insurancePartner

            if (list && list.data) {

                const length = list.data.length

                let group = list.data.reduce((r, a) => {
                    r[a.acf.status] = [...r[a.acf.status] || [], a];
                    return r;
                }, {});

                return (
                    <main style={{minHeight: 'calc(100vh - 16.5rem)'}}>
                        <section className="s-dashboard">
                            <div className="o-container o-container--primary">

                                <header className="c-heading c-heading--split">
                                    <h1>Gekoppelde assurantiebedrijven <small>({length})</small></h1>
                                    <div className="c-heading__cta">

                                        <a onClick={this._exportPartners} className="btn btn--outline-orange">
                                            <span>Exporteer bedrijven <small>(CSV)</small></span>
                                        </a>


                                        <Link to={RouteEnum.Map} className="btn btn--blue btn--icon">
                                            <img src={Pin}/>
                                            <span>Kaartweergave</span>
                                        </Link>

                                    </div>
                                </header>

                                <div className="c-info-cards__row">

                                    {Object.keys(group).map((key) => {

                                        const items = group[key];
                                        const statusType = StatusTypes()[key]

                                        return (
                                            <>
                                                <div className="c-info-cards__subject">
                                                    <figure className="c-info-cards__subject__color"
                                                            style={{backgroundColor: statusType.color}}/>
                                                    <span>{key} <small> ({items.length})</small></span>
                                                </div>

                                                {items && items.map((insuranceItem) => {
                                                    return <InsurancePartner color={statusType.color}
                                                                             data={insuranceItem} openInsurancePartnerId={this.openInsurancePartner}/>
                                                })}
                                            </>
                                        )

                                    })}

                                </div>

                            </div>
                        </section>

                        {!!this.state.insurancePartner &&

                        <div className="c-floating-card">
                            <div className="c-floating-card__bg"/>

                            {!!list.data &&
                            Object.values(list.data).map((data) => (
                                <InsurancePartnerDetail {...data} insurancePartnerId={this.state.insurancePartner} closeInsurancePartnerId={this.closeInsurancePartner}/>
                            ))}

                        </div>

                        }

                    </main>
                );
            } else return (
                <main style={{
                    minHeight: 'calc(100vh - 16.5rem)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <p style={{fontSize: '1.5rem'}}>Vooralsnog geen partners gekoppeld</p>
                </main>
            )
        } else return (
            <div className="c-loader-container" style={{
                height: 'calc(100vh - 16.5rem)',
                width: '100vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#fff'
            }}>

                <Loader
                    type="Bars"
                    color="#E67A01"
                    height={30}
                    width={30}
                />

            </div>
        )
    }

    _exportPartners = () => {
        this.setState({loading: true})
        this.props.dispatch(InsurancePartnerAction.exportPartners()).then(() => {
            this.setState({loading: false})
        })
    }

}

export default connect(mapStateToProps)(Dashboard)
