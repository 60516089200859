import React from 'react'
import {connect} from "react-redux";
import AppAction from "../../stores/app/AppAction";

const mapStateToProps = (state) => ({
    app: state.app
})

class Onboarding extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            activeStep: 1
        }

    }

    render() {

        const {onboarding} = this.props.app.appData.data

        if (onboarding) {

            const onboardStepData = onboarding[this.state.activeStep - 1]

            if (onboardStepData) {

                const {titel, inhoud, afbeelding} = onboardStepData

                return (
                    <section className="s-onboarding">
                        <header className="s-onboarding__header">
                            <img src="images/abs-autoherstel-logo.svg" alt=""/>
                        </header>
                        <div className="s-onboarding__slider">
                            <div className="s-onboarding__single">
                                <div className="s-onboarding__single__text">

                                    <small>{this.state.activeStep} / {onboarding.length}</small>
                                    <h1>{titel}</h1>
                                    <section dangerouslySetInnerHTML={{__html: inhoud}}/>


                                    <div style={{marginTop: '1.5rem'}}>

                                        {this.state.activeStep !== 1 &&
                                        <button className="btn btn--outline-orange" onClick={() => this.mutateStep(-1)}>
                                            <span>Vorige stap</span></button>
                                        }

                                        {this.state.activeStep < onboarding.length &&
                                        <button className="btn btn--blue" onClick={() => this.mutateStep(+1)}><span>Volgende stap</span>
                                        </button>
                                        }

                                        {this.state.activeStep === onboarding.length &&
                                        <button className="btn btn--orange" onClick={() => this.finishOnboarding()}><span>Aan de slag</span></button>
                                        }

                                    </div>

                                </div>
                                {!!afbeelding &&
                                <div className="s-onboarding__single__media">
                                    <img src={afbeelding.sizes['full-hd']} alt=""/>
                                </div>
                                }
                            </div>
                        </div>

                        <footer className="s-onboarding__skip"><a onClick={() => this.finishOnboarding()} className="btn btn--text">Introductie
                            overslaan</a></footer>

                    </section>
                )
            } else return null

        } else return null
    }

    mutateStep = (i) => {

        this.setState({
            activeStep: this.state.activeStep + i
        })

    }

    finishOnboarding = () => {
        this.props.dispatch(AppAction.setState({
            onboarding: {
                viewed: true
            }
        })).then(() => {
            localStorage.setItem('onBoardingViewed', true)
        })
    }


}

export default connect(mapStateToProps)(Onboarding)