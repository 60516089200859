import React from 'react'
import {connect} from "react-redux";
import {Link} from 'react-router-dom'
import InsurancePartnerAction from "../../../stores/insurancePartner/InsurancePartnerAction";
import StatusTypes from "../../../constants/StatusTypes";
import Loader from "react-loader-spinner";

const mapStateToProps = (state) => ({
    insurancePartner: state.insurancePartner.detail,
    updatePartner: state.insurancePartner.updatePartner
})

class CreatePartner extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            createNew: true,
            loading: false,
            addressError: false,
            locationFoundError: false,
            partnerData: {
                partnerCode: '',
                selectedStatus: false,
                companyName: '',
                street: '',
                houseNr: '',
                addOn: '',
                zipCode: '',
                city: '',
                generalEmail: '',
                generalPhone: '',
                firstName: '',
                insertion: '',
                lastName: '',
                function: '',
                email: '',
                phone: '',
                mobile: ''
            }
        }

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        this.setState({
            partnerData: {
                ...this.state.partnerData,
                [event.target.name]: event.target.value
            }
        });
    }

    updateFields = () => {

        this.setState({
            loading: true
        })

        this.props.dispatch(InsurancePartnerAction.partnerByCode(this.state.partnerData.partnerCode)).then(() => {
            if (this.props.insurancePartner.status.code !== 404){
                if (this.props.insurancePartner) {
                    this.setState({
                        loading: false,
                        createNew: false,
                        partnerData: {
                            ...this.state.partnerData,
                            selectedStatus: this.props.insurancePartner.data.acf.status,
                            companyName: this.props.insurancePartner.data.companyinfo.bedrijfsnaam,
                            street: this.props.insurancePartner.data.companyinfo.straatnaam,
                            houseNr: this.props.insurancePartner.data.companyinfo.huisnr,
                            addOn: this.props.insurancePartner.data.companyinfo.toevoeging,
                            zipCode: this.props.insurancePartner.data.companyinfo.postcode,
                            city: this.props.insurancePartner.data.companyinfo.plaats,
                            generalEmail: this.props.insurancePartner.data.companyinfo['algemeen_e-mailadres'],
                            generalPhone: this.props.insurancePartner.data.companyinfo.algemeen_telefoonnr,
                            firstName: this.props.insurancePartner.data.contactperson.voornaam,
                            insertion: this.props.insurancePartner.data.contactperson.tussenvoegsel,
                            lastName: this.props.insurancePartner.data.contactperson.achternaam,
                            function: this.props.insurancePartner.data.contactperson.functie,
                            email: this.props.insurancePartner.data.contactperson['e-mailadres'],
                            phone: this.props.insurancePartner.data.contactperson.telefoonnummer,
                            mobile: this.props.insurancePartner.data.contactperson.mobiel
                        }
                    })
                } else {
                    this.setState({
                        loading: false
                    })
                }
            }else{
                this.setState({
                    locationFoundError: true,
                    loading: false
                })
            }
        })

    }

    render() {
        if (!this.state.loading) {

            const statusTypes = StatusTypes();
            let statusTypeI = 0

            return (
                <main style={{minHeight: 'calc(100vh - 16.5rem)'}}>
                    <section className="s-change-status">
                        <div className="o-container o-container--primary">
                            <header className="c-heading c-heading--text">
                                <h1>Assurantiebedrijf toevoegen</h1>
                            </header>

                            <form className="c-form">
                                <div className="c-form__row c-form__row--button-row">
                                    <div className="c-form__group">
                                        <label htmlFor="">Assurantiecode invoeren</label>
                                        <input type="text" name="partnerCode"
                                               value={this.state.partnerData.partnerCode}
                                               onChange={this.handleChange}/>
                                    </div>
                                    <button className="btn btn--blue" onClick={this.updateFields}><span>Code invoeren</span></button>
                                </div>
                                {!!this.state.locationFoundError && <p style={{fontSize: '2rem', color: 'red', margin: '3rem 0 0'}}>Assurantiecode bestaat niet</p>}
                                <div className="c-form__break"/>
                                <div className="c-form__split">
                                    <div className="c-form__half">
                                        <h4>Bedrijfsinformatie</h4>
                                        <div className="c-form__row">
                                            <div className="c-form__group">
                                                <label htmlFor="text">Bedrijfsnaam</label>
                                                <input type="text" name="companyName"
                                                       value={this.state.partnerData.companyName}
                                                       onChange={this.handleChange}/>
                                            </div>
                                        </div>
                                        <div className="c-form__row c-form__row--address">
                                            <div className="c-form__group">
                                                <label htmlFor="text">Straatnaam</label>
                                                <input type="text" name="street"
                                                       value={this.state.partnerData.street}
                                                       onChange={this.handleChange}/>
                                            </div>
                                            <div className="c-form__group">
                                                <label htmlFor="number">Huisnr.</label>
                                                <input type="number" name="houseNr"
                                                       value={this.state.partnerData.houseNr}
                                                       onChange={this.handleChange}/>
                                            </div>
                                            <div className="c-form__group">
                                                <label htmlFor="text">Toevoeging</label>
                                                <input type="text" name="addOn" value={this.state.partnerData.addOn}
                                                       onChange={this.handleChange}/>
                                            </div>
                                        </div>
                                        <div className="c-form__row c-form__row--half">
                                            <div className="c-form__group">
                                                <label htmlFor="text">Postcode</label>
                                                <input type="text" name="zipCode"
                                                       value={this.state.partnerData.zipCode}
                                                       onChange={this.handleChange}/>
                                            </div>
                                            <div className="c-form__group">
                                                <label htmlFor="text">Plaats</label>
                                                <input type="text" name="city" value={this.state.partnerData.city}
                                                       onChange={this.handleChange}/>
                                            </div>
                                        </div>
                                        <div className="c-form__row">
                                            <div className="c-form__group">
                                                <label htmlFor="email">Algemeen e-mailadres</label>
                                                <input type="email" name="generalEmail"
                                                       value={this.state.partnerData.generalEmail}
                                                       onChange={this.handleChange}/>
                                            </div>
                                        </div>
                                        <div className="c-form__row">
                                            <div className="c-form__group">
                                                <label htmlFor="text">Algemeen telefoonnr.</label>
                                                <input type="text" name="generalPhone"
                                                       value={this.state.partnerData.generalPhone}
                                                       onChange={this.handleChange}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="c-form__half">
                                        <h4>Contactpersoon</h4>
                                        <div className="c-form__row c-form__row--name">
                                            <div className="c-form__group">
                                                <label htmlFor="text">Voornaam</label>
                                                <input type="text" name="firstName"
                                                       value={this.state.partnerData.firstName}
                                                       onChange={this.handleChange}/>
                                            </div>
                                            <div className="c-form__group">
                                                <label htmlFor="text">Tussenv.</label>
                                                <input type="text" name="insertion"
                                                       value={this.state.partnerData.insertion}
                                                       onChange={this.handleChange}/>
                                            </div>
                                            <div className="c-form__group">
                                                <label htmlFor="text">Achternaam</label>
                                                <input type="text" name="lastName"
                                                       value={this.state.partnerData.lastName}
                                                       onChange={this.handleChange}/>
                                            </div>
                                        </div>
                                        <div className="c-form__row">
                                            <div className="c-form__group">
                                                <label htmlFor="text">Functie binnen bedrijf</label>
                                                <input type="text" name="function"
                                                       value={this.state.partnerData.function}
                                                       onChange={this.handleChange}/>
                                            </div>
                                        </div>
                                        <div className="c-form__row">
                                            <div className="c-form__group">
                                                <label htmlFor="email">E-mailadres</label>
                                                <input type="email" name="email"
                                                       value={this.state.partnerData.email}
                                                       onChange={this.handleChange}/>
                                            </div>
                                        </div>
                                        <div className="c-form__row">
                                            <div className="c-form__group">
                                                <label htmlFor="text">Telefoonnr.</label>
                                                <input type="text" name="phone" value={this.state.partnerData.phone}
                                                       onChange={this.handleChange}/>
                                            </div>
                                        </div>
                                        <div className="c-form__row">
                                            <div className="c-form__group">
                                                <label htmlFor="text">Mobielnr.</label>
                                                <input type="text" name="mobile"
                                                       value={this.state.partnerData.mobile}
                                                       onChange={this.handleChange}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            {!!this.state.addressError && <p style={{fontSize: '2rem', color: 'red', margin: '3rem 0 0'}}>Geen valide adres ingevuld</p>}
                            <div className="c-form__break"/>
                            <div className="c-status-card__intro">
                                <h4>Status van samenwerking <small>Selecteer een status</small></h4>
                            </div>

                            <div className="c-status-card">
                                {statusTypes && Object.keys(statusTypes).map((statusTypeKey) => {
                                    statusTypeI++
                                    const statusType = statusTypes[statusTypeKey]
                                    let className = 'c-status-card__single c-status-card__single--' + statusTypeI

                                    if (this.state.partnerData.selectedStatus === statusTypeKey) {
                                        className += ' js-isActive'
                                    }

                                    return (
                                        <button className={className}
                                                onClick={() => this._clickStatus(statusTypeKey)}
                                                style={{borderColor: statusType.color}}>
                                            <div className="c-status-card__single__check" style={{
                                                backgroundColor: statusType.color,
                                                borderColor: statusType.color
                                            }}>
                                                <svg width="34px" height="29px" viewBox="0 0 34 29" version="1.1">
                                                    <g id="ABS-Assurantie-platform" stroke="none" stroke-width="1"
                                                       fill="none"
                                                       fill-rule="evenodd">
                                                        <g id="Assurantiebedrijf-toevoegen---2"
                                                           transform="translate(-821.000000, -929.000000)"
                                                           fill="#FFFFFF"
                                                           fill-rule="nonzero">
                                                            <g id="Group-5"
                                                               transform="translate(718.000000, 874.000000)">
                                                                <polygon id="Path-8"
                                                                         points="132.629658 55 137 58.7511916 115.784868 84 103 71.9864329 106.904058 67.7422948 115.296503 75.628577"></polygon>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div>
                                                <h3 style={{color: statusType.color}}>{statusTypeKey}</h3>
                                                <p>
                                                    {statusType.text}
                                                </p>
                                            </div>
                                        </button>
                                    )
                                })}

                            </div>

                            <footer className="c-footing">
                                <button onClick={() => this._submitStatus()} className="btn btn--blue"><span>Bedrijf toevoegen</span>
                                </button>
                                <Link to={'/'} className="btn btn--outline-blue"><span>Annuleren</span></Link>
                            </footer>
                        </div>
                    </section>

                </main>
            )


        } else return (
            <div className="c-loader-container" style={{
                height: 'calc(100vh - 16.5rem)',
                width: '100vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#fff'
            }}>
                <Loader
                    type="Oval"
                    color="#E67A01"
                    height={30}
                    width={30}
                />
            </div>
        )
    }


    _clickStatus = (statusType) => {
        this.setState({
            partnerData: {
                ...this.state.partnerData,
                selectedStatus: statusType
            }
        })
    }

    _submitStatus = () => {
        this.setState({loading: true})
        if (this.state.createNew){
            this.props.dispatch(InsurancePartnerAction.createPartner(this.state.partnerData)).then(() => {
                if (this.props.insurancePartner.status.code !== 405){
                    this.props.history.push("/create-assurantie-partner-confirm/");
                }else{
                    this.setState({addressError: true})
                }
                this.setState({loading: false})
            })
        }else{
            this.props.dispatch(InsurancePartnerAction.updatePartner(this.state.partnerData)).then(() => {
                if (this.props.updatePartner.status.code !== 405){
                    this.props.history.push("/create-assurantie-partner-confirm/");
                }else{
                    this.setState({addressError: true})
                }
                this.setState({loading: false})
            })
        }
    }

}

export default connect(mapStateToProps)(CreatePartner)
