import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import UserReducer from './user/UserReducer';
import AppReducer from "./app/AppReducer";
import LocationReducer from "./location/LocationReducer";
import InsurancePartnerReducer from "./insurancePartner/InsurancePartnerReducer";

export default (history) => {

    const reducerMap = {
        router: connectRouter(history),
        user: UserReducer.reducer,
        app: AppReducer.reducer,
        location: LocationReducer.reducer,
        insurancePartner: InsurancePartnerReducer.reducer
    };

    return combineReducers(reducerMap);
};
