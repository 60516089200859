import LocationAction from "./LocationAction";

export default class LocationReducer {

    static initialState = {
        self: null,
        activeLocation: false
    }

    static reducer(state = LocationReducer.initialState, action) {
        switch (action.type) {

            case LocationAction.REQUEST_ME:

                return {
                    ...state,
                    self: null
                };
            case LocationAction.REQUEST_ME + '_FINISHED':

                if (action.payload.data && action.payload.data[0]) {
                    return {
                        ...state,
                        activeLocation: action.payload.data[0].meta.ID,
                        self: action.payload,
                    };
                } else return {
                    self: action.payload,
                }

            case LocationAction.SET_ACTIVE_LOCATION + '_FINISHED':

                return {
                    ...state,
                    activeLocation: action.payload
                }


            default:
                return state;

        }
    }

}
