import React from "react";

const AuthLogo = (props) => {

    return (
        <img className="c-auth__logo" src="/images/abs-autoherstel-logo.svg" alt=""/>
    )


}

export default AuthLogo