import ApiEnum from "../../constants/ApiEnum";

export default class LocationEffect {

    static async requestMe(userId) {

        return fetch(ApiEnum.Api + '/location/me', {
            method: 'POST',
            body: JSON.stringify({
                userId: userId
            }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }).then((response) => {
            return response.json()
        }).then((response) => {
            return {
                ...response
            }
        }).catch(function (error) {
            return {
                status: error,
            }
        })

    }

    static setActiveLocation(value) {
        return value
    }

}
