import React from 'react'
import {connect} from "react-redux";
import RouteEnum from "../../constants/RouteEnum";
import {Link} from "react-router-dom";
import AuthMedia from "./components/AuthMedia";
import UserAction from "../../stores/user/UserAction";
import Loader from "react-loader-spinner";
import AuthLogo from "./components/AuthLogo";


const mapStateToProps = (state, ownProps) => ({
    user: state.user,
    app: state.app
});

class ResetPassword extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            password: '',
            passwordConfirm: '',
            loading: false
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault()

        this.setState({
                loading: true
            }
        )

        if (this.state.password === this.state.passwordConfirm) {

            const updateData = {
                id: this.props.match.params.id,
                hash: this.props.match.params.hash,
                password: this.state.password,
                passwordConfirm: this.state.passwordConfirm
            }

            this.props.dispatch(UserAction.requestUpdatePassword(updateData)).then(() => {
                this.setState({
                        loading: false
                    }
                )
            })

        } else {
            this.setState({
                    loading: true
                }
            )
        }

    }

    render() {


        const {password, passwordConfirm} = this.state
        const {forgotPassword} = this.props.app.appData.data.authenticate
        const backgroundImage = forgotPassword.afbeelding
        const userForgotPassword = this.props.user.updatePassword.data


        return (
            <section className="c-auth">
                <div className="c-auth__interaction">

                    <AuthLogo {...this.props}/>

                    <div className="c-auth__form">
                        <h1>Wachtwoord instellen</h1>


                        <form action="" className="c-form" onSubmit={this.handleSubmit}>

                            {(!userForgotPassword && userForgotPassword.code !== 200) &&
                            <>

                                <div className="c-form__row">
                                    <div className="c-form__group">
                                        <label htmlFor="">Wachtwoord</label>
                                        <input type="password" name={'password'} value={password}
                                               onChange={this.handleChange}/>
                                    </div>
                                </div>

                                <div className="c-form__row">
                                    <div className="c-form__group">
                                        <label htmlFor="">Wachtwoord herhalen</label>
                                        <input type="password" name={'passwordConfirm'} value={passwordConfirm}
                                               onChange={this.handleChange}/>
                                    </div>
                                </div>

                                {this.state.password !== this.state.passwordConfirm &&
                                <p style={{fontSize: '1.5rem'}}>De wachtwoorden komen niet overeen</p>
                                }

                                {!this.state.loading && this.state.password === this.state.passwordConfirm && this.state.password &&
                                <div className="c-form__row">
                                    <div className="c-form__group">
                                        <button className="btn btn--blue" type={'submit'}>
                                            <span>Bevestigen</span>
                                        </button>
                                    </div>
                                </div>
                                }

                                {this.state.loading &&
                                <div className={'c-loader'}
                                     style={{display: 'flex', justifyContent: 'center', padding: '0 0 2rem'}}>
                                    <Loader
                                        type="Bars"
                                        color="#E67A01"
                                        height={30}
                                        width={30}
                                    />
                                </div>


                                }
                            </>
                            }

                            {userForgotPassword && <p style={{fontSize: '1.6rem'}}>{userForgotPassword.message}</p>}


                            <div className="c-form__row">
                                <div className="c-form__group">
                                    <Link to={RouteEnum.Login} className="btn btn--text">Inloggen</Link>
                                </div>
                            </div>

                        </form>


                    </div>
                </div>

                {backgroundImage && <AuthMedia backgroundImage={backgroundImage}/>}

            </section>
        )
    }

    _clickLogin() {
        this.props.history.push(RouteEnum.Login)
    }

}

export default connect(mapStateToProps)(ResetPassword);
