import React from 'react'
import {connect} from "react-redux";
import UserAction from "../stores/user/UserAction";

const mapStateToProps = (state) => ({
    app: state.app,
    location: state.location
})


class DashboardFooter extends React.Component {


    render() {

        if (this.props.location.self.data) {

            const location = this.props.location.self.data.find((item) => item.meta.ID === this.props.location.activeLocation)

            if (location) {

                const {contactInfo} = location

                return (
                    <footer className="c-footer">
                        <span><strong>{contactInfo.bedrijfsnaam}</strong> - {contactInfo.straatnaam} {contactInfo.huisnummer}{contactInfo.huisnummer_toevoeging}, {contactInfo.postcode} {contactInfo.plaats}</span>
                        <a onClick={() => this._logoutClick()} href="">Uitloggen</a>
                    </footer>
                )

            } else return null

        } else return null

    }

    _logoutClick = () => {
        this.props.dispatch(UserAction.requestSignOut())
    }

}

export default connect(mapStateToProps)(DashboardFooter)