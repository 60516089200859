import ActionUtility from "../../utilities/etc/ActionUtility";
import LocationEffect from "./LocationEffect";


export default class LocationAction {

    static REQUEST_ME = 'LocationAction.REQUEST_ME';
    static SET_ACTIVE_LOCATION = 'LocationAction.SET_ACTIVE_LOCATION';

    static requestMe() {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, LocationAction.REQUEST_ME, LocationEffect.requestMe, getState().user.userData.data.id);
        }
    }

    static setActiveLocation(locationId) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, LocationAction.SET_ACTIVE_LOCATION, LocationEffect.setActiveLocation, locationId);
        }
    }

}
