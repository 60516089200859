import React from 'react'
import UserAction from "../../stores/user/UserAction";
import {connect} from "react-redux";
import AuthMedia from "./components/AuthMedia";
import AuthLogo from "./components/AuthLogo";
import Loader from "react-loader-spinner";
import RouteEnum from "../../constants/RouteEnum";
import {Link} from 'react-router-dom'

const mapStateToProps = (state, ownProps) => ({
    user: state.user,
    app: state.app
});


class ActivateAccount extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            token: '',
            email: '',
            loading: false
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault()

        this.setState({
            loading: true
        })

        const LoginData = {
            email: this.state.email,
        }

        this.props.dispatch(UserAction.requestActivateAccount(LoginData)).then(() => {
            this.setState({
                loading: false
            })
        })

    }

    render() {

        const {email} = this.state
        const {forgotPassword} = this.props.app.appData.data.authenticate

        const userForgotPassword = this.props.user.activateAccount.data

        const backgroundImage = forgotPassword.afbeelding

        return (
            <section className="c-auth">
                <div className="c-auth__interaction">

                    <AuthLogo {...this.props}/>

                    <div className="c-auth__form">
                        <h1>Account activeren</h1>
                        <form action="" className="c-form" onSubmit={this.handleSubmit}>

                            {(!userForgotPassword && userForgotPassword.code !== 200) &&

                            <>
                                <div className="c-form__row">
                                    <div className="c-form__group">
                                        <label htmlFor="">E-mailadres</label>
                                        <input type="email" name={'email'} value={email} onChange={this.handleChange}/>
                                    </div>
                                </div>

                                {!this.state.loading &&
                                <div className="c-form__row">
                                    <div className="c-form__group">
                                        <button className="btn btn--blue" type={'submit'}>
                                            <span>Activeren</span>
                                        </button>
                                    </div>
                                </div>
                                }

                                {this.state.loading &&
                                <div className={'c-loader'}
                                     style={{display: 'flex', justifyContent: 'center', padding: '0 0 2rem'}}>
                                    <Loader
                                        type="Bars"
                                        color="#E67A01"
                                        height={30}
                                        width={30}
                                    />
                                </div>
                                }

                                <div className="c-form__row">
                                    <div className="c-form__group">
                                        <Link to={RouteEnum.Login} className="btn btn--text">Inloggen</Link>
                                    </div>
                                </div>

                            </>
                            }


                            {userForgotPassword &&
                            <p style={{fontSize: '1.4rem'}}>{userForgotPassword.message}</p>
                            }


                        </form>
                    </div>
                </div>

                {backgroundImage && <AuthMedia backgroundImage={backgroundImage}/>}

            </section>
        )
    }

}

export default connect(mapStateToProps)(ActivateAccount);