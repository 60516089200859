import ActionUtility from "../../utilities/etc/ActionUtility";
import InsurancePartnerEffect from "./InsurancePartnerEffect";


export default class InsurancePartnerAction {

    static REQUEST_LIST = 'InsurancePartnerAction.REQUEST_LIST';
    static REQUEST_DETAIL = 'InsurancePartnerAction.REQUEST_DETAIL';
    static UPDATE_PARTNER = 'InsurancePartnerAction.UPDATE_PARTNER';
    static CREATE_PARTNER = 'InsurancePartnerAction.CREATE_PARTNER';
    static PARTNER_CODE = 'InsurancePartnerAction.PARTNER_CODE';
    static EXPORT_PARTNER = 'InsurancePartnerAction.EXPORT_PARTNER';
    static UPDATE_STATUS = 'InsurancePartnerAction.UPDATE_STATUS';
    static UPDATE_CONTACT = 'InsurancePartnerAction.UPDATE_CONTACT';

    static requestList() {

        return async (dispatch, getState) => {

            await ActionUtility.createThunkEffect(dispatch, InsurancePartnerAction.REQUEST_LIST, InsurancePartnerEffect.requestList, getState().location.activeLocation);
        }

    }

    static requestDetail(id) {

        return async (dispatch, getState) => {

            const values = {
                insurancePartnerId: id,
                locationId: getState().location.activeLocation
            }

            await ActionUtility.createThunkEffect(dispatch, InsurancePartnerAction.REQUEST_DETAIL, InsurancePartnerEffect.requestDetail, values);
        }

    }

    static partnerByCode(partnerCode) {

        return async (dispatch, getState) => {

            const values = {
                partnerCode: partnerCode,
                locationId: getState().location.activeLocation,
            }

            await ActionUtility.createThunkEffect(dispatch, InsurancePartnerAction.PARTNER_CODE, InsurancePartnerEffect.partnerByCode, values);
        }

    }

    static exportPartners() {

        return async (dispatch, getState) => {

            const values = {
                locationId: getState().location.activeLocation,
            }

            await ActionUtility.createThunkEffect(dispatch, InsurancePartnerAction.EXPORT_PARTNER, InsurancePartnerEffect.exportPartners, values);
        }

    }


    static createPartner(partnerData) {

        return async (dispatch, getState) => {

            const values = {
                locationId: getState().location.activeLocation,
                partnerData: partnerData
            }

            await ActionUtility.createThunkEffect(dispatch, InsurancePartnerAction.CREATE_PARTNER, InsurancePartnerEffect.createPartner, values);
        }

    }


    static updatePartner(partnerData) {

        return async (dispatch, getState) => {

            const values = {
                insurancePartnerId: getState().insurancePartner.detail.data.meta.ID,
                locationId: getState().location.activeLocation,
                partnerData: partnerData
            }

            await ActionUtility.createThunkEffect(dispatch, InsurancePartnerAction.UPDATE_PARTNER, InsurancePartnerEffect.updatePartner, values);
        }

    }

    static updateStatus(status) {

        return async (dispatch, getState) => {

            const values = {
                insurancePartnerId: getState().insurancePartner.detail.data.meta.ID,
                locationId: getState().location.activeLocation,
                status: status
            }

            await ActionUtility.createThunkEffect(dispatch, InsurancePartnerAction.UPDATE_STATUS, InsurancePartnerEffect.updateStatus, values);
        }

    }

    static updateContact(contact) {

        return async (dispatch, getState) => {

            const values = {
                insurancePartnerId: getState().insurancePartner.detail.data.meta.ID,
                locationId: getState().location.activeLocation,
                contact: contact
            }

            await ActionUtility.createThunkEffect(dispatch, InsurancePartnerAction.UPDATE_CONTACT, InsurancePartnerEffect.updateContact, values);
        }

    }

}
