import React from 'react'
import {connect} from "react-redux";
import {Link} from "react-router-dom"
import Loader from "react-loader-spinner";
import CalculateMapBounds from "../../constants/CalculateMapBounds";
import plus from "../../assets/images/plus-white.svg"
import InsurancePartnerAction from "../../stores/insurancePartner/InsurancePartnerAction";
import ReactMapboxGl, {Marker} from "react-mapbox-gl";
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import RouteEnum from "../../constants/RouteEnum";
import InsurancePartnerDetail from "./_components/InsurancePartnerDetail";
import StatusTypes from "../../constants/StatusTypes";
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const mapStateToProps = (state) => ({
    app: state.app,
    location: state.location,
    insurancePartner: state.insurancePartner
})

const Map = ReactMapboxGl({
    accessToken: 'pk.eyJ1IjoiYmFydDk5MiIsImEiOiJja2t6YWZvNjA1a2NnMnBxdHR3OWcwamVxIn0.jmqJ1sdkWatA2frBRr3vZg'
});


class DashboardMap extends React.Component {

    constructor(props) {
        super(props);

        const {list} = this.props.insurancePartner
        const locations = [];

        if(list && list.data){
            Object.values(list.data).map((data) => {
                locations.push(data.acf.locatie)
            })
        }

        this.state = {
            loading: false,
            insurancePartner: null,
            bounds: CalculateMapBounds(locations)
        }
    }

    init() {

        this.setState({
            loading: true
        })


        this.props.dispatch(InsurancePartnerAction.requestList()).then(() => {
            this.setState({
                loading: false
            })
        })
    }

    componentDidMount() {
        this.init()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.location !== prevProps.location) {
            this.init()
        }

    }

    openInsurancePartner = (id) => {
        this.setState({
            insurancePartner: id,
        })
    }

    closeInsurancePartner = () => {
        this.setState({
            insurancePartner: null,
        })
    }

    render() {

        if (!this.state.loading) {

            const {list} = this.props.insurancePartner

            if (list && list.data) {

                return (
                    <main style={{minHeight: 'calc(100vh - 16.5rem)'}}>
                        <section className="s-dashboard-map" style={{}}>
                            <div className="c-map">
                                <Link to={RouteEnum.Home} className="btn btn--icon btn--blue">
                                    <img src={plus} alt=""/>
                                    <span>Kaart sluiten</span>
                                </Link>

                                <Map
                                    style="mapbox://styles/bart992/ckkzaiqbv3ex217oaq5q1ymsm"
                                    fitBounds={this.state.bounds}
                                    containerStyle={{
                                        height: '100%',
                                        width: '100%'
                                    }}
                                >

                                    {!!list.data &&
                                    Object.values(list.data).map((data) => {
                                        const statusType = StatusTypes()[data.acf.status]
                                        return (
                                            <Marker coordinates={[data.acf.locatie.lng, data.acf.locatie.lat]}
                                                    anchor="bottom"
                                                    onClick={() => this.openInsurancePartner(data.meta.ID)}>
                                                <svg width="auto" height="50px" viewBox="0 0 103 154">
                                                    <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                                        <circle fill={statusType.color} cx="50" cy="50" r="30"/>
                                                        <path d="M51.3997105,0 C23.0584474,0 5.68434189e-14,23.0287148 5.68434189e-14,51.3334336 C5.68434189e-14,59.8305039 2.12716082,68.2553867 6.17096491,75.7279961 L48.5888947,152.345703 C49.1535877,153.367156 50.2296667,154 51.3997105,154 C52.5697544,154 53.6458333,153.367156 54.2105263,152.345703 L96.644117,75.7027305 C100.67226,68.2553867 102.799421,59.8302031 102.799421,51.3331328 C102.799421,23.0287148 79.7409737,0 51.3997105,0 Z M51.3997105,77 C37.2290789,77 25.7000058,65.485793 25.7000058,51.3334336 C25.7000058,37.1810742 37.2290789,25.6668672 51.3997105,25.6668672 C65.5703421,25.6668672 77.0994152,37.1810742 77.0994152,51.3334336 C77.0994152,65.485793 65.5703421,77 51.3997105,77 Z" id="Shape-Copy-3" fill="#1D2D58"/>
                                                    </g>
                                                </svg>
                                            </Marker>
                                        )
                                    })}

                                </Map>
                            </div>
                        </section>

                        {!!this.state.insurancePartner &&

                        <div className="c-floating-card">
                            <div className="c-floating-card__bg"/>

                            {!!list.data &&
                            Object.values(list.data).map((data) => (
                                <InsurancePartnerDetail {...data} insurancePartnerId={this.state.insurancePartner} closeInsurancePartnerId={this.closeInsurancePartner}/>
                            ))}

                        </div>

                        }

                    </main>
                );
            } else return (
                <main style={{
                    minHeight: 'calc(100vh - 16.5rem)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <p style={{fontSize: '1.5rem'}}>Vooralsnog geen partners gekoppeld</p>
                </main>
            )
        } else return (
            <div className="c-loader-container" style={{
                height: 'calc(100vh - 16.5rem)',
                width: '100vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#fff'
            }}>

                <Loader
                    type="Bars"
                    color="#E67A01"
                    height={30}
                    width={30}
                />

            </div>
        )


    }
}

export default connect(mapStateToProps)(DashboardMap)
