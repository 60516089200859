import React from 'react'
import {connect} from "react-redux";
import LocationAction from "../stores/location/LocationAction";
import {Link} from "react-router-dom";
import RouteEnum from "../constants/RouteEnum";

const mapStateToProps = (state) => ({
    app: state.app,
    location: state.location
})


class DashboardHeader extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            multipleLocations: false,
            showLocationSelector: false
        }

    }

    componentDidMount() {
        if (this.props.location.self.data.length > 1) {
            this.setState({
                multipleLocations: true
            })
        }
    }

    render() {

        if (this.props.location.self.data) {

            const location = this.props.location.self.data.find((item) => item.meta.ID === this.props.location.activeLocation)

            if (location) {

                const {contactInfo} = location

                return (
                    <>
                        <header className="c-header">

                            {contactInfo &&
                            <div className="c-header__profile">
                                <img src="/images/icon-profile.svg" alt=""/>
                                {!!this.state.multipleLocations ? (
                                    <button className="select-btn" onClick={() => this._clickLocationSelector()}>
                                        <div><small>ABS Autoherstel</small><span>{contactInfo.bedrijfsnaam}</span>
                                        </div>
                                        <img src="/images/arrow-down-blue.svg" alt=""/>
                                    </button>
                                ) : (
                                    <div className="select-btn">
                                        <div><small>ABS Autoherstel</small><span>{contactInfo.bedrijfsnaam}</span></div>
                                    </div>
                                )}
                            </div>
                            }


                            <Link to={RouteEnum.Home} className="c-header__logo">
                                <img src="/images/abs-autoherstel-logo.svg" alt=""/>
                            </Link>

                            <Link to={RouteEnum.InsurancePartnerCreate} className="btn btn--outline-orange btn--icon">
                                <img src="/images/plus-orange.svg" alt=""/>
                                <span>Bedrijf toevoegen</span>
                            </Link>

                        </header>

                        {this.state.showLocationSelector &&
                        <div className="c-stores"
                             style={{padding: '2rem', height: 'auto', pointerEvents: 'all', opacity: 1}}>
                            <div className="o-container o-container--primary">
                                <header className="c-stores__header">
                                    <small>ABS Autoherstel</small>
                                </header>

                                {this.props.location.self.data.map((location) => {
                                    return (
                                        <a className="c-stores__single"
                                           onClick={() => this._clickLocation(location.meta.ID)}><span>{location.meta.post_title}</span></a>)
                                })}

                            </div>
                        </div>
                        }
                    </>
                )

            } else return null

        } else return null
    }

    _clickLocationSelector = () => {
        this.setState({
            showLocationSelector: !this.state.showLocationSelector
        })
    }

    _clickLocation = (locationId) => {
        this.setState({
            showLocationSelector: !this.state.showLocationSelector
        })

        this.props.dispatch(LocationAction.setActiveLocation(locationId))
    }

}

export default connect(mapStateToProps)(DashboardHeader)
