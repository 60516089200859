import React from 'react'
import {connect} from "react-redux";
import {Link} from 'react-router-dom'
import InsurancePartnerAction from "../../../stores/insurancePartner/InsurancePartnerAction";
import Loader from "react-loader-spinner";

const mapStateToProps = (state) => ({
    insurancePartner: state.insurancePartner.detail
})

class EditPartner extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            partnerData: {
                firstName: '',
                insertion: '',
                lastName: '',
                function: '',
                email: '',
                phone: '',
                mobile: ''
            }
        }

        this.handleChange = this.handleChange.bind(this)
    }


    init() {
        const id = this.props.match.params.id;

        this.setState({
            loading: true
        })

        this.props.dispatch(InsurancePartnerAction.requestDetail(id)).then(() => {
            if (this.props.insurancePartner && this.props.insurancePartner.data.contactperson) {
                this.setState({
                    loading: false,
                    partnerData: {
                        firstName: this.props.insurancePartner.data.contactperson.voornaam,
                        insertion: this.props.insurancePartner.data.contactperson.tussenvoegsel,
                        lastName: this.props.insurancePartner.data.contactperson.achternaam,
                        function: this.props.insurancePartner.data.contactperson.functie,
                        email: this.props.insurancePartner.data.contactperson['e-mailadres'],
                        phone: this.props.insurancePartner.data.contactperson.telefoonnummer,
                        mobile: this.props.insurancePartner.data.contactperson.mobiel
                    }
                })
            } else {
                this.setState({
                    loading: false
                })
            }
        })

    }

    componentDidMount() {
        this.init()


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.match.params.id !== this.props.match.params.id)) {
            this.init()
        }
    }

    handleChange(event) {
        this.setState({
            partnerData:{
                ...this.state.partnerData,
                [event.target.name]: event.target.value
            }
        });
    }


    render() {
        if (!this.state.loading) {

            const {insurancePartner} = this.props

            if (insurancePartner) {

                const {meta} = insurancePartner.data

                return (
                    <main style={{minHeight: 'calc(100vh - 16.5rem)'}}>
                        <section className="s-change-status">
                            <div className="o-container o-container--primary">
                                <header className="c-heading c-heading--text">
                                    <h1>{meta.post_title}</h1>
                                </header>

                                <form className="c-form">
                                    <div className="c-form__split">
                                        <div className="c-form__half">
                                            <h4>Contactpersoon</h4>
                                            <div className="c-form__row c-form__row--name">
                                                <div className="c-form__group">
                                                    <label htmlFor="text">Voornaam</label>
                                                    <input type="text" name="firstName"
                                                           value={this.state.partnerData.firstName}
                                                           onChange={this.handleChange}/>
                                                </div>
                                                <div className="c-form__group">
                                                    <label htmlFor="text">Tussenv.</label>
                                                    <input type="text" name="insertion"
                                                           value={this.state.partnerData.insertion}
                                                           onChange={this.handleChange}/>
                                                </div>
                                                <div className="c-form__group">
                                                    <label htmlFor="text">Achternaam</label>
                                                    <input type="text" name="lastName"
                                                           value={this.state.partnerData.lastName}
                                                           onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                            <div className="c-form__row">
                                                <div className="c-form__group">
                                                    <label htmlFor="text">Functie binnen bedrijf</label>
                                                    <input type="text" name="function"
                                                           value={this.state.partnerData.function}
                                                           onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                            <div className="c-form__row">
                                                <div className="c-form__group">
                                                    <label htmlFor="email">E-mailadres</label>
                                                    <input type="email" name="email"
                                                           value={this.state.partnerData.email}
                                                           onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                            <div className="c-form__row">
                                                <div className="c-form__group">
                                                    <label htmlFor="text">Telefoonnr.</label>
                                                    <input type="text" name="phone" value={this.state.partnerData.phone}
                                                           onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                            <div className="c-form__row">
                                                <div className="c-form__group">
                                                    <label htmlFor="text">Mobielnr.</label>
                                                    <input type="text" name="mobile"
                                                           value={this.state.partnerData.mobile}
                                                           onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                                <footer className="c-footing">
                                    <button onClick={() => this._submitStatus()} className="btn btn--blue"><span>Bedrijf aanpassen</span>
                                    </button>
                                    <Link to={'/'} className="btn btn--outline-blue"><span>Annuleren</span></Link>
                                </footer>
                            </div>
                        </section>

                    </main>
                )
            } else return null

        } else return (
            <div className="c-loader-container" style={{
                height: 'calc(100vh - 16.5rem)',
                width: '100vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#fff'
            }}>
                <Loader
                    type="Oval"
                    color="#E67A01"
                    height={30}
                    width={30}
                />
            </div>
        )
    }


    _clickStatus = (statusType) => {
        this.setState({
            partnerData:{
                ...this.state.partnerData,
                selectedStatus: statusType
            }
        })
    }

    _submitStatus = () => {
        this.setState({loading: true})
        this.props.dispatch(InsurancePartnerAction.updateContact(this.state.partnerData)).then(() => {
            this.props.history.push("/");
            this.setState({loading: false})
        })
    }

}

export default connect(mapStateToProps)(EditPartner)
