import ActionUtility from "../../utilities/etc/ActionUtility";
import AppEffect from "./AppEffect";


export default class AppAction {

    static REQUEST_DATA = 'AppAction.REQUEST_DATA';
    static SET_STATE = 'AppAction.SET_STATE';

    static requestData() {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.REQUEST_DATA, AppEffect.requestData);
        }

    }

    static setState(value){
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AppAction.SET_STATE, AppEffect.setState, value);
        }
    }

}
