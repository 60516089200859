import React from "react";
import plus from "../../../assets/images/plus-white.svg";
import profile from "../../../assets/images/profile-orange.svg"
import {Link} from "react-router-dom"
import StatusTypes from "../../../constants/StatusTypes";
import RouteEnum from "../../../constants/RouteEnum";

class InsurancePartnerDetail extends React.Component {

    render() {

        let itemClass = 'c-floating-card__single'

        if (this.props.insurancePartnerId === this.props.meta.ID) {
            itemClass += ' js-floating-active'
        }

        const statusType = StatusTypes()[this.props.acf.status]

        return (

            <div className={itemClass}>
                <button className="c-floating-card__close" onClick={() => this.props.closeInsurancePartnerId()}>
                    <img src={plus} alt="close"/>
                </button>
                <div className="c-floating-card__single__info">
                    {!!this.props.meta &&
                    <h2>{this.props.meta.post_title}</h2>
                    }
                    {!!this.props.companyinfo &&
                    <>
                        <p>{this.props.companyinfo.straatnaam} {this.props.companyinfo.huisnr} {this.props.companyinfo.toevoeging}</p>
                        <p>{this.props.companyinfo.postcode}{!!this.props.companyinfo.plaats && <>, {this.props.companyinfo.plaats} </>}</p>
                    </>
                    }
                    <Link to={RouteEnum.InsurancePartner + this.props.meta.ID}
                          className="btn btn--outline-orange"><span>Algemene gegevens aanpassen</span></Link>
                </div>
                <div className="c-floating-card__single__info">
                    <h3>Contactpersoon</h3>
                    {!!this.props.contactperson.achternaam ? (
                        <>
                            <div className="c-floating-card__profile">
                                <img src={profile} alt="profile"/>
                                <div>
                                    <span>{this.props.contactperson.voornaam} {this.props.contactperson.tussenvoegsel} {this.props.contactperson.achternaam}</span>
                                    <small>{this.props.contactperson.functie}</small>
                                </div>
                            </div>
                            <p>{this.props.contactperson['e-mailadres']}</p>
                            <p>{this.props.contactperson.telefoonnummer}</p>
                        </>
                    ) : (
                        <>
                            <div className="c-floating-card__profile">
                                <img src={profile} alt="profile"/>
                                <div>
                                    <span>Geen contactpersoon toegevoegd</span>
                                </div>
                            </div>
                        </>
                    )
                    }
                    <Link to={RouteEnum.InsuranceContact + this.props.meta.ID}
                          className="btn btn--outline-orange"><span>Contactpersoon beheren</span></Link>
                </div>
                <div className="c-floating-card__single__info">
                    <h3>Status van samenwerking</h3>
                    <div className="c-info-cards__subject">
                        <figure className="c-info-cards__subject__color" style={{background: statusType.color}}/>
                        <span>{this.props.acf.status}</span>
                    </div>
                    <Link to={RouteEnum.InsuranceStatus + this.props.meta.ID} className="btn btn--outline-orange"><span>Status aanpassen</span></Link>
                </div>
            </div>

        )
    }


}

export default InsurancePartnerDetail
