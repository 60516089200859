import ApiEnum from "../../constants/ApiEnum";

export default class InsurancePartnerEffect {

    static async requestList(locationId) {

        return fetch(ApiEnum.Api + '/insurance-partner/list?location-id=' + locationId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }).then((response) => {
            return response.json()
        }).then((response) => {
            return {
                ...response
            }
        }).catch(function (error) {
            return {
                status: error,
            }
        })

    }

    static async requestDetail(values) {

        return fetch(ApiEnum.Api + '/insurance-partner/detail?location-id=' + values.locationId + '&insurance-id=' + values.insurancePartnerId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }).then((response) => {
            return response.json()
        }).then((response) => {
            return {
                ...response
            }
        }).catch(function (error) {
            return {
                status: error,
            }
        })

    }

    static async partnerByCode(values) {

        return fetch(ApiEnum.Api + '/insurance-partner/partner-by-code?location-id=' + values.locationId, {
            method: 'POST',
            body: JSON.stringify({
                partnerCode: values.partnerCode
            }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }).then((response) => {
            return response.json()
        }).then((response) => {
            return {
                ...response
            }
        }).catch(function (error) {
            return {
                status: error,
            }
        })

    }


    static async exportPartners(values) {

        return fetch(ApiEnum.Api + '/insurance-partner/export-partners?location-id=' + values.locationId, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }).then((response) => {
            return response.json()
        }).then((response) => {
            let a = document.createElement('a');
            a.href = response;
            a.download = 'employees.json';
            a.click();
            return {
                url: response,
            }
        }).catch(function (error) {
            return {
                status: error,
            }
        })

    }

    static async updatePartner(values) {

        return fetch(ApiEnum.Api + '/insurance-partner/update-partner?location-id=' + values.locationId + '&insurance-id=' + values.insurancePartnerId, {
            method: 'POST',
            body: JSON.stringify({
                partnerData: values.partnerData
            }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }).then((response) => {
            return response.json()
        }).then((response) => {
            return {
                ...response
            }
        }).catch(function (error) {
            return {
                status: error,
            }
        })

    }

    static async createPartner(values) {

        return fetch(ApiEnum.Api + '/insurance-partner/create?location-id=' + values.locationId, {
            method: 'POST',
            body: JSON.stringify({
                partnerData: values.partnerData
            }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }).then((response) => {
            return response.json()
        }).then((response) => {
            return {
                ...response
            }
        }).catch(function (error) {
            return {
                status: error,
            }
        })

    }

    static async updateStatus(values) {

        return fetch(ApiEnum.Api + '/insurance-partner/update-status?location-id=' + values.locationId + '&insurance-id=' + values.insurancePartnerId, {
            method: 'POST',
            body: JSON.stringify({
                status: values.status
            }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }).then((response) => {
            return response.json()
        }).then((response) => {
            return {
                ...response
            }
        }).catch(function (error) {
            return {
                status: error,
            }
        })

    }

    static async updateContact(values) {

        return fetch(ApiEnum.Api + '/insurance-partner/update-contact?location-id=' + values.locationId + '&insurance-id=' + values.insurancePartnerId, {
            method: 'POST',
            body: JSON.stringify({
                contact: values.contact
            }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }).then((response) => {
            return response.json()
        }).then((response) => {
            return {
                ...response
            }
        }).catch(function (error) {
            return {
                status: error,
            }
        })

    }

}
