import ApiEnum from "../../constants/ApiEnum";

export default class AppEffect {

    static async requestData() {

        return fetch(ApiEnum.Api + '/app/data', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
            return response.json()
        }).then((response) => {
            return response
        }).catch(function (error) {
            return {
                status: error,
            }
        })

    }

    static setState(value) {
        return value
    }

}
