import AppAction from "./AppAction";

export default class AppReducer {

    static initialState = {

        appData: null,
        onboarding: {
            viewed: localStorage.getItem('onBoardingViewed')
        }

    }

    static reducer(state = AppReducer.initialState, action) {
        switch (action.type) {

            case AppAction.REQUEST_DATA:
                return {
                    ...state,
                    appData: null
                };
            case AppAction.REQUEST_DATA + '_FINISHED':

                return {
                    ...state,
                    appData: action.payload,
                };

            case AppAction.SET_STATE:
                return {
                    ...state,
                };
            case AppAction.SET_STATE + '_FINISHED':

                return {
                    ...state,
                    ...action.payload
                };

            default:
                return state;

        }
    }

}
