import React from 'react'
import {connect} from "react-redux";
import {Link} from 'react-router-dom'
import InsurancePartnerAction from "../../../stores/insurancePartner/InsurancePartnerAction";
import StatusTypes from "../../../constants/StatusTypes";
import Loader from "react-loader-spinner";

const mapStateToProps = (state) => ({
    insurancePartner: state.insurancePartner.detail
})

class EditStatus extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedStatus: false,
            loading: true
        }
    }



    init() {
        const id = this.props.match.params.id;

        this.setState({
            loading: true
        })

        this.props.dispatch(InsurancePartnerAction.requestDetail(id)).then(() => {
            if (this.props.insurancePartner) {
                this.setState({
                    selectedStatus: this.props.insurancePartner.data.acf.status,
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                })
            }
        })

    }

    componentDidMount() {
        this.init()


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.match.params.id !== this.props.match.params.id)) {
            this.init()
        }
    }


    render() {

        if (!this.state.loading) {

            const {insurancePartner} = this.props

            if (insurancePartner) {

                const {meta} = insurancePartner.data

                const statusTypes = StatusTypes();
                let statusTypeI = 0

                return (
                    <main style={{minHeight: 'calc(100vh - 16.5rem)'}}>
                        <section className="s-change-status">
                            <div className="o-container o-container--primary">
                                <header className="c-heading c-heading--text">
                                    <small>Status van samenwerking updaten</small>
                                    <h1>{meta.post_title}</h1>
                                </header>

                                <div className="c-status-card">
                                    {statusTypes && Object.keys(statusTypes).map((statusTypeKey) => {
                                        statusTypeI++
                                        const statusType = statusTypes[statusTypeKey]
                                        let className = 'c-status-card__single c-status-card__single--' + statusTypeI

                                        if (this.state.selectedStatus === statusTypeKey) {
                                            className += ' js-isActive'
                                        }

                                        return (
                                            <button className={className}
                                                    onClick={() => this._clickStatus(statusTypeKey)}
                                                    style={{borderColor: statusType.color}}>
                                                <div className="c-status-card__single__check" style={{
                                                    backgroundColor: statusType.color,
                                                    borderColor: statusType.color
                                                }}>
                                                    <svg width="34px" height="29px" viewBox="0 0 34 29" version="1.1">
                                                        <g id="ABS-Assurantie-platform" stroke="none" stroke-width="1"
                                                           fill="none"
                                                           fill-rule="evenodd">
                                                            <g id="Assurantiebedrijf-toevoegen---2"
                                                               transform="translate(-821.000000, -929.000000)"
                                                               fill="#FFFFFF"
                                                               fill-rule="nonzero">
                                                                <g id="Group-5"
                                                                   transform="translate(718.000000, 874.000000)">
                                                                    <polygon id="Path-8"
                                                                             points="132.629658 55 137 58.7511916 115.784868 84 103 71.9864329 106.904058 67.7422948 115.296503 75.628577"></polygon>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </div>
                                                <div>
                                                    <h3 style={{color: statusType.color}}>{statusTypeKey}</h3>
                                                    <p>
                                                        {statusType.text}
                                                    </p>
                                                </div>
                                            </button>
                                        )
                                    })}

                                </div>

                                <footer className="c-footing">
                                    <button onClick={() => this._submitStatus()} className="btn btn--blue"><span>Status aanpassen</span>
                                    </button>
                                    <Link to={'/'} className="btn btn--outline-blue"><span>Annuleren</span></Link>
                                </footer>
                            </div>
                        </section>

                    </main>
                )
            } else return null

        } else return (
            <div className="c-loader-container" style={{
                height: 'calc(100vh - 16.5rem)',
                width: '100vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#fff'
            }}>
                <Loader
                    type="Oval"
                    color="#E67A01"
                    height={30}
                    width={30}
                />
            </div>
        )
    }


    _clickStatus = (statusType) => {
        this.setState({
            selectedStatus: statusType
        })
    }

    _submitStatus = () => {
        this.setState({loading: true})
        this.props.dispatch(InsurancePartnerAction.updateStatus(this.state.selectedStatus)).then(() => {
            this.props.history.push("/");
            this.setState({loading: false})

        })
    }

}

export default connect(mapStateToProps)(EditStatus)
