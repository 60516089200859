import ActionUtility from "../../utilities/etc/ActionUtility";
import UserEffect from "./UserEffect";


export default class UserAction {

    static REQUEST_SIGNIN = 'UserAction.REQUEST_SIGNIN';
    static REQUEST_USER_DATA = 'UserAction.REQUEST_USER_DATA';

    static REQUEST_CHANGE = 'UserAction.REQUEST_CHANGE';
    static REQUEST_CHANGE_PASSWORD = 'UserAction.REQUEST_CHANGE_PASSWORD';
    static REQUEST_SIGNOUT = 'UserAction.REQUEST_SIGNOUT';
    static REQUEST_UPDATE_PASSWORD = 'UserAction.REQUEST_UPDATE_PASSWORD';
    static REQUEST_FORGOT_PASSWORD = 'UserAction.REQUEST_FORGOT_PASSWORD';
    static REQUEST_ACTIVATE_ACCOUNT = 'UserAction.REQUEST_ACTIVATE_ACCOUNT';

    static requestSignIn(loginData) {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, UserAction.REQUEST_SIGNIN, UserEffect.requestSignIn, loginData);
        }

    }

    static requestUserData() {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, UserAction.REQUEST_USER_DATA, UserEffect.requestUserData, getState().user.signIn.data.token);
        }

    }

    static requestCreate(createData) {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, UserAction.REQUEST_CREATE, UserEffect.requestCreate, createData);
        }

    }

    static requestRegister() {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, UserAction.REQUEST_REGISTER, UserEffect.requestRegister);
        }

    }

    static requestChange(changeData) {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, UserAction.REQUEST_CHANGE, UserEffect.requestChange, changeData);
        }

    }

    static requestChangePassword(changePasswordData) {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, UserAction.REQUEST_CHANGE_PASSWORD, UserEffect.requestChangePassword, changePasswordData);
        }

    }

    static requestUpdatePassword(passwordData) {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, UserAction.REQUEST_UPDATE_PASSWORD, UserEffect.requestUpdatePassword, passwordData);
        }

    }

    static requestForgotPassword(forgotData) {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, UserAction.REQUEST_FORGOT_PASSWORD, UserEffect.requestForgotPassword, forgotData);
        }

    }

    static requestActivateAccount(accountData) {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, UserAction.REQUEST_ACTIVATE_ACCOUNT, UserEffect.requestActivateAccount, accountData);
        }

    }

    static requestDeactivate() {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, UserAction.REQUEST_DEACTIVATE, UserEffect.requestDeactivate);
        }

    }

    static requestSignOut() {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, UserAction.REQUEST_SIGNOUT, UserEffect.requestSignOut);
        }

    }

}
