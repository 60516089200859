import React from "react";
import Profile from "../../../assets/images/profile-orange.svg"

class InsurancePartnerDetail extends React.Component {

    render() {

        const {data} = this.props

        return (
            <div className="c-info-cards__single" style={{borderLeft: "5px solid " + this.props.color}}>

                <div className="c-info-cards__single__info">
                    {!!data.meta &&
                    <span>{data.meta.post_title}</span>
                    }
                    {!!data.companyinfo &&
                    <small>{data.companyinfo.straatnaam} {data.companyinfo.huisnr}{!!data.companyinfo.toevoeging && <> {data.companyinfo.toevoeging}</>}{!!data.companyinfo.plaats && <>, {data.companyinfo.plaats} </>}</small>
                    }
                </div>
                {!!data.contactperson.achternaam ? (
                <div className="c-info-cards__single__profile">
                    <img src={Profile} alt=""/>
                    <div>
                        <span>{data.contactperson.voornaam} {data.contactperson.tussenvoegsel} {data.contactperson.achternaam}</span>
                        <small>{data.contactperson.telefoonnummer}{!!data.contactperson['e-mailadres'] && data.contactperson.telefoonnummer && <>,</>} {data.contactperson['e-mailadres']}</small>
                    </div>
                </div>
                ) : (
                    <div className="c-info-cards__single__profile">
                        <img src={Profile} alt=""/>
                        <div>
                            <span>Geen contactpersoon toegevoegd</span>
                        </div>
                    </div>
                )
                }

                <button onClick={() => this.props.openInsurancePartnerId(data.meta.ID)} className="btn btn--blue"><span>Beheren</span>
                </button>

            </div>
        )
    }

}

export default InsurancePartnerDetail
