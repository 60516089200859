import React from 'react'
import {connect} from "react-redux";
import RouteEnum from "../../../constants/RouteEnum";
import {Link} from "react-router-dom";

const mapStateToProps = (state) => ({
    insurancePartner: state.insurancePartner.detail
})

class CreatePartnerConfirmation extends React.Component {

    render() {

            return (
                <main style={{minHeight: 'calc(100vh - 16.5rem)'}}>
                    <section className="s-add-company">
                        <div className="o-container o-container--primary">
                            <header className="c-heading c-heading--text">
                                <small>Gelukt!</small>
                                <h1>Assurantiebedrijf toegevoegd</h1>
                            </header>
                            <p>
                                Bedankt voor het toevoegen van het assurantiebedrijf.
                            </p>
                            <footer className="c-footing">
                                <Link to={RouteEnum.Home} className="btn btn--blue">
                                    <span>Terug naar dashboard</span>
                                </Link>
                            </footer>
                        </div>
                    </section>
                </main>
            )
    }

}

export default connect(mapStateToProps)(CreatePartnerConfirmation)
