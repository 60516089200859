import UserAction from "./UserAction";

export default class UserReducer {

    static initialState = {

        signIn: {
            data: {
                token: localStorage.getItem('token'),
                email: localStorage.getItem('email'),
                username: localStorage.getItem('username'),
                id: localStorage.getItem('id'),
                tokenDate: localStorage.getItem('tokenDate'),
                userData: false
            },
            error: false
        },

        signOut: false,
        changePassword: {
            data: false,
            error: false
        },
        userData: {
            data: false,
            error: false
        },
        updatePassword: {
            data: false,
            error: false
        },
        forgotPassword: {
            error: false,
            data: false
        },
        activateAccount: {
            error: false,
            data: false
        }


    }

    static reducer(state = UserReducer.initialState, action) {

        switch (action.type) {


            case UserAction.REQUEST_SIGNIN:
                return {
                    ...state,
                    signIn: {
                        error: false,
                        data: false
                    },
                };

            case UserAction.REQUEST_SIGNIN + '_FINISHED':


                return {
                    ...state,
                    signIn: {
                        ...action.payload
                    },
                };

            case UserAction.REQUEST_USER_DATA:
                return {
                    ...state,
                    userData: {
                        error: false,
                        data: false
                    },
                };

            case UserAction.REQUEST_USER_DATA + '_FINISHED':


                if (action.payload) {
                    return {
                        ...state,
                        userData: {
                            ...action.payload
                        },
                    };
                }else return {
                    ...state
                }


            case UserAction.REQUEST_SIGNOUT:
                return {
                    ...state,
                    signIn: {
                        error: false,
                        data: false
                    },
                    signOut: false,
                };
            case UserAction.REQUEST_SIGNOUT + '_FINISHED':
                return {
                    ...state,
                    signIn: {
                        error: false,
                        data: false
                    },
                    signOut: true,
                };

            case UserAction.REQUEST_CHANGE_PASSWORD:
                return {
                    ...state,
                    changePassword: {
                        error: false,
                        data: false
                    }
                };
            case UserAction.REQUEST_CHANGE_PASSWORD + '_FINISHED':
                return {
                    ...state,
                    changePassword: {
                        error: action.payload.error,
                        data: action.payload.data
                    }
                };
            case UserAction.REQUEST_UPDATE_PASSWORD:
                return {
                    ...state,
                    updatePassword: {
                        error: false,
                        data: false
                    }
                };
            case UserAction.REQUEST_UPDATE_PASSWORD + '_FINISHED':
                return {
                    ...state,
                    updatePassword: {
                        error: action.payload.error,
                        data: action.payload.data
                    }
                };
            case UserAction.REQUEST_FORGOT_PASSWORD:
                return {
                    ...state,
                    forgotPassword: {
                        error: false,
                        data: false
                    }
                };
            case UserAction.REQUEST_FORGOT_PASSWORD + '_FINISHED':

                return {
                    ...state,
                    forgotPassword: {
                        error: action.payload.error,
                        data: action.payload.data
                    }
                };

            case UserAction.REQUEST_ACTIVATE_ACCOUNT:
                return {
                    ...state,
                    activateAccount: {
                        error: false,
                        data: false
                    }
                };
            case UserAction.REQUEST_ACTIVATE_ACCOUNT + '_FINISHED':

                return {
                    ...state,
                    activateAccount: {
                        error: action.payload.error,
                        data: action.payload.data
                    }
                };
            default:
                return state;

        }
    }

}
