import React from 'react'
import Authentication from "./views/Authentication";
import LoggedIn from "./views/LoggedIn";
import {connect} from "react-redux";
import AppAction from "./stores/app/AppAction";
import UserAction from "./stores/user/UserAction";
import LocationAction from "./stores/location/LocationAction";
import Loader from "react-loader-spinner";
import DashboardHeader from "./components/DashboardHeader";
import DashboardFooter from "./components/DashboardFooter";
import {HashRouter} from "react-router-dom";


const mapStateToProps = (state, ownProps) => ({
    user: state.user,
    app: state.app,
    location: state.location
});

class App extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            loading: false
        }
    }


    componentDidMount() {
        this.props.dispatch(AppAction.requestData())
        this._init()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props.user.signIn !== prevProps.user.signIn) {
            this._init()
        }

    }


    _init = () => {

        this.setState({
            loading: true
        })

        if (this.props.user.signIn.data && this.props.user.signIn.data.token && this.props.user.signIn.data.token !== 'undefined') {
            this.props.dispatch(UserAction.requestUserData()).then(() => {

                if (this.props.user.userData.data) {

                    this.props.dispatch(LocationAction.requestMe()).then(() => {
                        this.setState({
                            loading: false
                        })
                    })

                } else{

                    this.setState({
                        loading: false
                    })

                }

            })
        } else {
            this.setState({
                loading: false
            })
        }
    }

    render() {


        const {appData} = this.props.app

        if (!this.state.loading) {

            if (appData && appData.status && appData.status.code === 200) {

                if (this.props.user.userData.data && this.props.location.self) {
                    return (
                        <HashRouter history={this.props.history} basename={''}>
                            <LoggedIn history={this.props.history}/>
                        </HashRouter>
                    )
                } else {
                    return <Authentication history={this.props.history}/>
                }

            } else return null

        } else return (
            <div className="c-loader-container" style={{
                height: '100vh',
                width: '100vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#1D2D58'
            }}>
                <Loader
                    type="ThreeDots"
                    color="#E67A01"
                    height={60}
                    width={60}
                />
            </div>)
    }

}

export default connect(mapStateToProps)(App);
