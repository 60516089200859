import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';

import rootReducer from './rootReducer';


export default (initialState, history) => {

    return createStore(rootReducer(history), initialState, applyMiddleware(thunk));

    // store.subscribe(() => console.log(store.getState()));

};
