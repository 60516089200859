import React from 'react'
import UserAction from "../../stores/user/UserAction";
import {connect} from "react-redux";
import AuthMedia from "./components/AuthMedia";
import AuthLogo from "./components/AuthLogo";
import Loader from "react-loader-spinner";
import RouteEnum from "../../constants/RouteEnum";
import {Link} from 'react-router-dom'

const mapStateToProps = (state) => ({
    user: state.user,
    app: state.app
});


class Login extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            token: '',
            username: '',
            password: '',
            loading: false
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault()

        this.setState({
            loading: true
        })

        const LoginData = {
            username: this.state.username,
            password: this.state.password
        }

        this.props.dispatch(UserAction.requestSignIn(LoginData)).then(() => {
            this.setState({
                loading: false
            })
        })

    }

    render() {

        const {username, password} = this.state
        const {login} = this.props.app.appData.data.authenticate
        const backgroundImage = login.afbeelding
        const {error} = this.props.user.signIn

        return (


            <section className="c-auth">
                <div className="c-auth__interaction">

                    <AuthLogo {...this.props}/>

                    <div className="c-auth__form">
                        <h1>{login.titel}</h1>



                        <form onSubmit={this.handleSubmit} className="c-form">
                            <div className="c-form__row">
                                <div className="c-form__group">
                                    <label htmlFor="">E-mailadres</label>
                                    <input id="username" name='username' required={true} value={username}
                                           onChange={this.handleChange}
                                           type="email"/>
                                </div>
                            </div>
                            <div className="c-form__row">
                                <div className="c-form__group">
                                    <label htmlFor="">Wachtwoord</label>
                                    <input type="password" id="password" required={true} name='password'
                                           value={password}
                                           onChange={this.handleChange}/>
                                </div>
                            </div>

                            {!!error && <p style={{fontSize: '1.4rem', color: 'red'}}>{error.message}</p>}


                            {!this.state.loading &&
                            <div className="c-form__row">
                                <div className="c-form__group">
                                    <button className="btn btn--blue"><span>Inloggen</span></button>
                                </div>
                            </div>
                            }

                            {this.state.loading &&
                            <div className={'c-loader'}
                                 style={{display: 'flex', justifyContent: 'center', padding: '0 0 2rem'}}>
                                <Loader
                                    type="Bars"
                                    color="#E67A01"
                                    height={30}
                                    width={30}
                                />
                            </div>
                            }

                            <div className="c-form__row">
                                <div className="c-form__group">
                                    <Link to={RouteEnum.ForgotPassword} className="btn btn--text">Wachtwoord
                                        vergeten</Link>
                                </div>
                            </div>
                            <div className="c-form__row">
                                <div className="c-form__group">
                                    <Link to={RouteEnum.ActivateAccount} className="btn btn--text">Account activeren</Link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {backgroundImage && <AuthMedia backgroundImage={backgroundImage}/>}

            </section>
        )
    }

}

export default connect(mapStateToProps)(Login);