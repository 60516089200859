import React from 'react'
import {Route, Switch, HashRouter} from 'react-router-dom'
import RouteEnum from "../constants/RouteEnum";
import {connect} from "react-redux";
import UserAction from "../stores/user/UserAction";
import Onboarding from "./onboarding/Onboarding";
import Dashboard from "./dashboard/Dashboard";
import DashboardHeader from "../components/DashboardHeader";
import DashboardFooter from "../components/DashboardFooter";
import DashboardMap from "./dashboard/DashboardMap";
import CreatePartner from "./insurancePartner/createPartner/CreatePartner";
import EditStatus from "./insurancePartner/updatePartner/EditStatus";
import EditContact from "./insurancePartner/updatePartner/EditContact";
import EditPartner from "./insurancePartner/updatePartner/EditPartner";
import CreatePartnerConfirmation from "./insurancePartner/createPartner/CreatePartnerConfirmation";

const mapStateToProps = (state, ownProps) => ({
    app: state.app
});

class LoggedIn extends React.Component {

    componentDidMount() {
        const currentTime = new Date().getTime()
        if (currentTime - localStorage.getItem('tokenDate') > 1000 * 60 * 60 * 24) {
            this.props.dispatch(UserAction.requestSignOut()).then(() => {
            })
        }
    }

    render() {

        const props = this.props

        if (this.props.app.onboarding.viewed) {
            return (
                <>

                    <DashboardHeader/>
                    <Switch>

                        <Route
                            exact
                            path={RouteEnum.InsuranceStatus + ':id'}
                            history={props.history}
                            component={EditStatus}
                        />

                        <Route
                            exact
                            path={RouteEnum.InsuranceContact + ':id'}
                            history={props.history}
                            component={EditContact}
                        />

                        <Route
                            exact
                            path={RouteEnum.InsurancePartner + ':id'}
                            history={props.history}
                            component={EditPartner}
                        />

                        <Route
                            exact
                            path={RouteEnum.InsurancePartnerCreate}
                            history={props.history}
                            component={CreatePartner}
                        />

                        <Route
                            exact
                            path={RouteEnum.InsurancePartnerCreateConfirm}
                            history={props.history}
                            component={CreatePartnerConfirmation}
                        />

                        <Route
                            exact
                            path={RouteEnum.Map}
                            history={props.history}
                            component={DashboardMap}
                        />

                        <Route component={Dashboard}/>


                    </Switch>

                    <DashboardFooter/>

                </>
            )
        } else return <Route component={Onboarding}/>


    }

}

export default connect(mapStateToProps)(LoggedIn);
