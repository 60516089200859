import React from "react";

const AuthMedia = (props) => {

    if (props.backgroundImage) {

        return (
            <div className="c-auth__media u-bg-cover-center"
                 style={{
                     background: 'url(' + props.backgroundImage.sizes['full-hd'] + ')',
                     backgroundPosition: 'center',
                     backgroundSize: 'cover'
                 }}/>
        )

    } else return null

}

export default AuthMedia