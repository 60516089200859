const RouteEnum = {
    Home: process.env.PUBLIC_URL + '/',
    Map: process.env.PUBLIC_URL + '/map/',
    Login: process.env.PUBLIC_URL + '/inloggen/',
    InsurancePartnerCreate: process.env.PUBLIC_URL + '/create-assurantie-partner/',
    InsurancePartnerCreateConfirm: process.env.PUBLIC_URL + '/create-assurantie-partner-confirm/',
    InsuranceStatus: process.env.PUBLIC_URL + '/assurantie-partner/status/',
    InsuranceContact: process.env.PUBLIC_URL + '/assurantie-partner/contact/',
    InsurancePartner: process.env.PUBLIC_URL + '/assurantie-partner/',
    ForgotPassword: process.env.PUBLIC_URL + '/wachtwoord-vergeten/',
    ResetPassword: process.env.PUBLIC_URL + '/reset-password/',
    ActivateAccount: process.env.PUBLIC_URL + '/account-activeren/',
}

export default RouteEnum;
