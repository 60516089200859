import InsurancePartnerAction from "./InsurancePartnerAction";

export default class InsurancePartnerReducer {

    static initialState = {
        list: null,
        detail: null,
        updatePartner: null,
        exportPartner: null,
        updateStatus: null,
        updateContact: null
    }

    static reducer(state = InsurancePartnerReducer.initialState, action) {
        switch (action.type) {

            case InsurancePartnerAction.REQUEST_LIST:
                return {
                    ...state,
                    list: null
                };
            case InsurancePartnerAction.REQUEST_LIST + '_FINISHED':

                return {
                    ...state,
                    list: action.payload,
                };

            case InsurancePartnerAction.REQUEST_DETAIL:
                return {
                    ...state,
                    detail: null
                };
            case InsurancePartnerAction.REQUEST_DETAIL + '_FINISHED':

                return {
                    ...state,
                    detail: action.payload,
                };

            case InsurancePartnerAction.EXPORT_PARTNER:
                return {
                    ...state,
                    exportPartner: null
                };
            case InsurancePartnerAction.EXPORT_PARTNER + '_FINISHED':
                return {
                    ...state,
                    exportPartner: action.payload,
                };

            case InsurancePartnerAction.PARTNER_CODE:
                return {
                    ...state,
                    detail: null
                };
            case InsurancePartnerAction.PARTNER_CODE + '_FINISHED':
                return {
                    ...state,
                    detail: action.payload,
                };
            case InsurancePartnerAction.UPDATE_PARTNER:
                return {
                    ...state,
                    updatePartner: null
                };
            case InsurancePartnerAction.UPDATE_PARTNER + '_FINISHED':

                return {
                    ...state,
                    updatePartner: action.payload,
                };
            case InsurancePartnerAction.CREATE_PARTNER:
                return {
                    ...state,
                    detail: null
                };
            case InsurancePartnerAction.CREATE_PARTNER + '_FINISHED':

                return {
                    ...state,
                    detail: action.payload,
                };
            case InsurancePartnerAction.UPDATE_STATUS:
                return {
                    ...state,
                    updateStatus: null
                };
            case InsurancePartnerAction.UPDATE_STATUS + '_FINISHED':

                return {
                    ...state,
                    updateStatus: action.payload,
                };
            case InsurancePartnerAction.UPDATE_CONTACT:
                return {
                    ...state,
                    updateContact: null
                };
            case InsurancePartnerAction.UPDATE_CONTACT + '_FINISHED':

                return {
                    ...state,
                    updateContact: action.payload,
                };


            default:
                return state;

        }
    }

}
