import React from "react";

const StatusTypes = () => {

    return {
        'Geen interesse': {
            color: 'red',
            text: 'Dit bedrijf heeft geen interesse in een samenwerking.'
        },
        'Nog geen contact': {
            color: '#9370db',
            text: 'Het bedrijf is bekend en er kan nu contact worden opgenomen.'
        },
        'Kennismaking': {
            color: 'green',
            text: 'Kennis gemaakt en er is interesse voor een samenwerking.'
        },
        'Samenwerking': {
            color: '#daa520',
            text: 'Na overleg met het bedrijf is het gekomen tot een samenwerking.'
        },
        'Bestaande relatie': {
            color: '#1E90FF',
            text: 'Er is al een samenwerking met het bedrijf.'
        },
    }

}

export default StatusTypes

