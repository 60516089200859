import ApiEnum from "../../constants/ApiEnum";

export default class UserEffect {

    static async requestSignIn(loginData) {

        return fetch(ApiEnum.Api + '/token', {
            method: 'post',
            body: JSON.stringify(loginData),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
            return response.json()
        }).then((response) => {

                localStorage.setItem('token', response.token)
                localStorage.setItem('email', response.user_email)
                localStorage.setItem('username', response.user_nicename)
                localStorage.setItem('tokenDate', new Date().getTime())

                if (response.data) {

                    switch (response.data.status) {

                        case(403): {
                            return {
                                data: null,
                                error: {
                                    code: 403,
                                    message: 'Onbekend gegevens. Probeer het opnieuw.'
                                }
                            }
                        }

                        default: {
                            return {
                                data: null,
                                error: {
                                    code: 0,
                                    message: 'Er is een onbekende fout opgetreden. Probeer het later opnieuw.'
                                }
                            }
                        }

                    }

                } else {
                    return {
                        data: {
                            token: response.token,
                            email: response.user_email,
                            username: response.user_nicename,
                            tokenDate: localStorage.getItem('tokenDate')
                        },
                        error: null
                    }
                }


            }
        ).catch(
            function (error) {

                return {
                    error: error,
                    message: error.message
                }
            }
        )

    }

    static async requestUserData(token) {

        return await fetch(ApiEnum.ApiBase + '/wp/v2/users/me', {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            return response.json()
        }).then((response) => {

            if (response.code !== 'jwt_auth_bad_iss' && response.code !== 'jwt_auth_invalid_token') {

                return {
                    data: response,
                }

            } else {
                localStorage.clear()
                return null
            }

        }).catch(function (error) {
            return {
                error: error,
                message: error.message
            }
        })

    }

    static async requestForgotPassword(values) {

        return await fetch(ApiEnum.Api + '/user/reset/password', {
            method: 'post',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
                return response.json()
            }
        )

    }

    static async requestActivateAccount(values) {

        return await fetch(ApiEnum.Api + '/user/account/activate', {
            method: 'post',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
                return response.json()
            }
        )

    }


    static async requestUpdatePassword(values) {

        return await fetch(ApiEnum.Api + '/user/update/password', {
            method: 'post',
            body: JSON.stringify(values),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then((response) => {
                return response.json()
            }
        )

    }

    static
    async requestSignOut() {

        localStorage.clear()
        return ''

    }

}
