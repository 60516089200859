import React from 'react'
import {connect} from "react-redux";
import {Link} from 'react-router-dom'
import InsurancePartnerAction from "../../../stores/insurancePartner/InsurancePartnerAction";
import Loader from "react-loader-spinner";

const mapStateToProps = (state) => ({
    insurancePartner: state.insurancePartner.detail,
    updatePartner: state.insurancePartner.updatePartner
})

class EditPartner extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            addressError: false,
            partnerData: {
                companyName: '',
                street: '',
                houseNr: '',
                addOn: '',
                zipCode: '',
                city: '',
                generalEmail: '',
                generalPhone: '',
            }
        }

        this.handleChange = this.handleChange.bind(this)
    }


    init() {
        const id = this.props.match.params.id;

        this.setState({
            loading: true
        })

        this.props.dispatch(InsurancePartnerAction.requestDetail(id)).then(() => {
            if (this.props.insurancePartner && this.props.insurancePartner.data.companyinfo) {
                this.setState({
                    loading: false,
                    partnerData: {
                        companyName: this.props.insurancePartner.data.companyinfo.bedrijfsnaam,
                        street: this.props.insurancePartner.data.companyinfo.straatnaam,
                        houseNr: this.props.insurancePartner.data.companyinfo.huisnr,
                        addOn: this.props.insurancePartner.data.companyinfo.toevoeging,
                        zipCode: this.props.insurancePartner.data.companyinfo.postcode,
                        city: this.props.insurancePartner.data.companyinfo.plaats,
                        generalEmail: this.props.insurancePartner.data.companyinfo['algemeen_e-mailadres'],
                        generalPhone: this.props.insurancePartner.data.companyinfo.algemeen_telefoonnr,
                    }
                })
            } else {
                this.setState({
                    loading: false
                })
            }
        })

    }

    componentDidMount() {
        this.init()


    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.match.params.id !== this.props.match.params.id)) {
            this.init()
        }
    }

    handleChange(event) {
        this.setState({
            partnerData:{
                ...this.state.partnerData,
                [event.target.name]: event.target.value
            }
        });
    }


    render() {
        if (!this.state.loading) {

            const {insurancePartner} = this.props

            if (insurancePartner) {

                const {meta} = insurancePartner.data

                return (
                    <main style={{minHeight: 'calc(100vh - 16.5rem)'}}>
                        <section className="s-change-status">
                            <div className="o-container o-container--primary">
                                <header className="c-heading c-heading--text">
                                    <h1>{meta.post_title}</h1>
                                </header>

                                <form className="c-form">
                                    <div className="c-form__split">
                                        <div className="c-form__half">
                                            <h4>Bedrijfsinformatie</h4>
                                            <div className="c-form__row">
                                                <div className="c-form__group">
                                                    <label htmlFor="text">Bedrijfsnaam</label>
                                                    <input type="text" name="companyName"
                                                           value={this.state.partnerData.companyName}
                                                           onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                            <div className="c-form__row c-form__row--address">
                                                <div className="c-form__group">
                                                    <label htmlFor="text">Straatnaam</label>
                                                    <input type="text" name="street"
                                                           value={this.state.partnerData.street}
                                                           onChange={this.handleChange}/>
                                                </div>
                                                <div className="c-form__group">
                                                    <label htmlFor="number">Huisnr.</label>
                                                    <input type="number" name="houseNr"
                                                           value={this.state.partnerData.houseNr}
                                                           onChange={this.handleChange}/>
                                                </div>
                                                <div className="c-form__group">
                                                    <label htmlFor="text">Toevoeging</label>
                                                    <input type="text" name="addOn" value={this.state.partnerData.addOn}
                                                           onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                            <div className="c-form__row c-form__row--half">
                                                <div className="c-form__group">
                                                    <label htmlFor="text">Postcode</label>
                                                    <input type="text" name="zipCode"
                                                           value={this.state.partnerData.zipCode}
                                                           onChange={this.handleChange}/>
                                                </div>
                                                <div className="c-form__group">
                                                    <label htmlFor="text">Plaats</label>
                                                    <input type="text" name="city" value={this.state.partnerData.city}
                                                           onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                            <div className="c-form__row">
                                                <div className="c-form__group">
                                                    <label htmlFor="email">Algemeen e-mailadres</label>
                                                    <input type="email" name="generalEmail"
                                                           value={this.state.partnerData.generalEmail}
                                                           onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                            <div className="c-form__row">
                                                <div className="c-form__group">
                                                    <label htmlFor="text">Algemeen telefoonnr.</label>
                                                    <input type="text" name="generalPhone"
                                                           value={this.state.partnerData.generalPhone}
                                                           onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>

                                {!!this.state.addressError && <p style={{fontSize: '2rem', color: 'red', margin: '3rem 0 0'}}>Geen valide adres ingevuld</p>}

                                <footer className="c-footing">
                                    <button onClick={() => this._submitStatus()} className="btn btn--blue"><span>Bedrijf aanpassen</span>
                                    </button>
                                    <Link to={'/'} className="btn btn--outline-blue"><span>Annuleren</span></Link>
                                </footer>
                            </div>
                        </section>

                    </main>
                )
            } else return null

        } else return (
            <div className="c-loader-container" style={{
                height: 'calc(100vh - 16.5rem)',
                width: '100vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#fff'
            }}>
                <Loader
                    type="Oval"
                    color="#E67A01"
                    height={30}
                    width={30}
                />
            </div>
        )
    }


    _submitStatus = () => {
        this.setState({loading: true})
        this.props.dispatch(InsurancePartnerAction.updatePartner(this.state.partnerData)).then(() => {
            if (this.props.updatePartner.status.code !== 405){
                this.props.history.push("/");
            }else{
                this.setState({addressError: true})
            }
            this.setState({loading: false})
        })
    }

}

export default connect(mapStateToProps)(EditPartner)
